<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid justify-content-center">
          <div class="col-12 md:col-6 comp-grid">
            <TabView :selectOnFocus="false" :scrollable="true" v-model:activeIndex="active">
              <TabPanel>
                <template #header>
                  <!-- <i class="pi pi-question-circle text-xl"></i> -->
                  <i class="text-sm py-3">{{ $t("Page Instructions") }}</i>
                  <!-- <span>{{ $t("How to Apply") }}</span> -->
                </template>
                <div class="reset-grid">
                  <div class="grid justify-content-center">
                    <div style="margin-top: 17vh;" class="my-1 card">
                      <div class="text-center text-2xl font-bold mb-3 underline">
                        How to Apply
                      </div>
                      <div class="font-bold underline">OPTION 1</div>
                      <ul>
                        <li>
                          <div class="text-primary">
                            Please visit any branch of Consolidated Bank Ghana (CBG) to
                            purchase a <b>serial number</b> and <b>pin</b> for this online application.
                          </div>
                          <div class="text-pink-500">
                            After purchase, please ensure your serial number and pin are visibly printed on your bank
                            receipt before leaving the premises. If you noticed your receipt has an invalid serial number
                            or pin,
                            please return to the same Bank for correction.
                          </div>
                        </li>
                      </ul>

                      <div class="font-bold underline">OPTION 2</div>
                      <ul>
                        <li>
                          <div class="text-primary">
                            You can purchase a <b>Voucher</b> using Mobile Money or Bank
                            Card.
                          </div>
                          <div class="my-2">
                            <Button label="Purchase Voucher" @click="active = 2" class="p-button-success w-full" />
                          </div>
                        </li>
                      </ul>

                      <div class="font-bold underline">OPTION 3</div>
                      <ul>
                        <li>
                          <div class="text-primary">
                            Dial <b>*924*200*3#</b> on all networks to purchase voucher.
                          </div>
                          <div>
                            If you already have a serial number and pin, please click the "Start Application" button
                          </div>
                        </li>
                      </ul>


                      <hr />
                      <div class="text-center mt-5">
                        <Button label="Start Application" @click="active = 1" class="px-4" />
                      </div>

                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <template #header>
                  <!-- <i class="pi pi-sign-in text-xl"></i> -->
                  <i class="text-sm py-3">{{ $t("My Application") }}</i>
                  <!-- <span>{{ $t("My Application") }}</span> -->
                </template>
                <div class="reset-grid">
                  <div class="grid justify-content-center">
                    <div class="col-12 comp-grid">
                      <div class="card mb-0">
                        <div class="text-muted text-mg  mb-2 font-medium px-2">
                          Start your application by entering your serial number and pin
                        </div>
                        <div>
                          <form ref="observer" tag="form" @submit.prevent="startLogin()" @reset="resetForm">
                            <div class="col-12">
                              <div class="formgrid grid">
                                <div class="col-12 md:col-12">
                                  <InputText :label="$t('serial_number')" :placeholder="$t('serial_number')"
                                    class="w-full code-input" v-model.trim="formData.serial_number" required="required"
                                    type="text" />
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="formgrid grid">
                                <div class="col-12 md:col-12">
                                  <InputText :label="$t('pin')" :placeholder="$t('pin')" class="w-full code-input"
                                    v-model.trim="formData.pin" required="required" type="text" />
                                </div>
                              </div>
                            </div>
                            <transition appear enter-active-class="animated bounceIn" v-if="errorMsg"
                              leave-active-class="animated fadeOut">
                              <Message severity="error" :closable="false" @close="errorMsg = ''">{{ errorMsg.toString() }}
                              </Message>
                            </transition>
                            <div class="text-center mx-2  mt-2 mb-4">
                              <Button :label="$t('login')" :loading="loading" class="w-full p-button-primary py-3"
                                type="submit">
                              </Button>
                            </div>
                          </form>
                        </div>
                        <hr>
                        <div class="text-center mt-3">
                          <div class="row text-center text-blue-500  mb-1">
                            {{ $t("Don't have a serial number and pin?") }}
                          </div>
                          <div>
                            <Button @click="active = 0" class="p-button-danger p-button-text"
                              label="Click here for more information" />
                          </div>
                          <!-- <div class="font-bold text-blue-700 ">Visit any branch of Consolidated Bank Ghana (CBG) to
                            purchase a serial number and pin for this online application.</div> -->
                          <!-- <Button :label="$t('Purchase Application Form')" @click="active = 1" class="p-button-danger p-button-link p-button-md" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <template #header>
                  <!-- <i class="pi pi-credit-card text-xl"></i> -->
                  <i class="text-sm py-3">{{ $t("Purchase Voucher") }}</i>
                </template>
                <div class="reset-grid">
                  <purchase-form-page></purchase-form-page>
                </div>
              </TabPanel>
              <TabPanel>
                <template #header>
                  <!-- <i class="pi pi-phone text-xl"></i> -->
                  <i class="text-sm py-3">{{ $t("Contact Us") }}</i>
                  <!-- <span>{{ $t("Contact Us") }}</span> -->
                </template>
                <div class="reset-grid">
                  <div class="card">
                    <div class="flex">
                      <div class="mr-3">
                        <Avatar style="background-color: #292866; color: #ffffff" icon="pi pi-question" shape="circle"
                          size="large" />
                      </div>
                      <div class="mb-2">
                        <span class="text-md">
                          For any queries please contact Admissions Office Main Hotline/Whatsapp Number
                          <b>(233)501579732</b>. Thank you
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { PageMixin } from "../../mixins/page.js";
import PurchaseFormPage from "./purchaseform.vue";
export default {
  name: "IndexComponent",
  components: {
    PurchaseFormPage
  },
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        serial_number: "",
        pin: "",
      },
      active: 0,
      rememberUser: false,
      loading: false,
      ready: false,
      errorMsg: "",
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Welcome to GCTU";
      },
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    startLogin() {
      this.loading = true;
      this.errorMsg = "";
      let formData = this.formData;
      let rememberUser = this.rememberUser;
      let url = "/auth/login";
      let payload = {
        formData,
        rememberUser,
        url,
      };
      this.login(payload).then(
        (response) => {
          this.loading = false;
          let loginData = response.data;
          if (loginData.token) {
            let nextUrl = this.$route.query.nexturl || "/home";
            this.$router.go(nextUrl);
            location.reload();
            //let nextUrl = this.$route.query.nexturl || '/home';
            //location.href= "#" + nextUrl;
            //location.reload();
          } else {
            this.$router.push(loginData.nextpage);
          }
        },
        (request) => {
          this.loading = false;
          this.errorMsg =
            request.response?.data || "Unable to establish connection...";
        }
      );
    },
    resetForm() {
      this.formData = {
        serial_number: "",
        pin: "",
      };
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
  async mounted() {
    this.ready = true;
  },
  async created() { },
};
</script>
<style>
.code-input {
  font-weight: bold;
  text-align: center;
  letter-spacing: 10px;
}

.code-input::placeholder {
  font-weight: normal;
  text-align: center;
  letter-spacing: 1px;
}
</style>